
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { ILaboratory, ILabsFilter, ILabsSearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class LaboratoriesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;

  filter: ILabsFilter = $app.clone(this.clinic.$store.LabsSearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: ILaboratory | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('dplus.labs.LabNumber'),
        value: 'LabNumber',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('system.Name'),
        value: 'LabName',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('dplus.labs.LabAddress'),
        value: 'LabAddress',
        align: 'left',
        width: '35%'
      },
      {
        text: $app.i18n('dplus.labs.LabEmail'),
        value: 'LabEmail',
        align: 'left',
        width: '15%'
      }
    ];
  }

  get search(): ILabsSearch {
    return this.clinic.$store.LabsSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findLabs(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.LabsSearch, this.filter);
    this.clinic.$store.setLabsSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: ILaboratory) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeLab(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: ILaboratory) {
    this.processItem = true;
    try {
      await this.clinic.$store.removeLab(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: ILaboratory) {
    this.selectedItem = item;
    this.showItem = true;
  }

  async append() {
    this.selectedItem = {
      id: null,
      code: '',
      name: 'NEW LABORATORY',
      phone: '',
      address: '',
      email: '',
      processing: false
    };
    this.showItem = true;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
